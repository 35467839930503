<template>
  <b-card>
    <b-row>
      <b-col cols="5">
        <h3>Marketing Reports</h3>
      </b-col>
      <b-col cols="2">
        <b-form-group class="w-100 mb-3" id="input-from-date" v-if="filterDate == -1">
          <datePicker
            v-model="from"
            :config="dpOptions"
            placeholder="From Date"
            @dp-change="getReportData()"
          ></datePicker>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group class="w-100 mb-3" id="input-to-date" v-if="filterDate == -1">
          <datePicker v-model="to" :config="dpOptions" placeholder="To Date" @dp-change="getReportData()"></datePicker>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-dropdown
          block
          :text="filterDateText()"
          variant="outline-primary"
          class="text-primary mb-3"
          menu-class="w-100"
        >
          <b-dropdown-item
            v-for="date in filterDateList"
            :active="activeFilteredDate(date)"
            :key="'filter_date_' + date.value"
            @click="changeFilterDate(date)"
            >{{ date.text }}</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
    </b-row>
    <b-table-simple striped responsive bordered class="elevation-1 export-table">
      <b-thead>
        <b-tr>
          <b-th v-for="(item, index) in headers" :key="'head_' + index" :class="item.class || ''">
            {{ item.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="items.length == 0 || isLoading">
        <b-tr>
          <b-th :colspan="headers.length">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </b-th>
        </b-tr>
      </b-tbody>
      <b-tbody v-else>
        <b-tr v-for="(item, index) in items" :key="'row_' + index">
          <b-th v-if="item.category" :rowspan="item.category.count" style="vertical-align: middle">
            <h5 class="mb-1">{{ item.category.title }}</h5>
            <span class="text-muted" style="font-style: italic; font-weight: 100">{{ item.category.note || '' }}</span>
          </b-th>
          <b-th>{{ item.title }}</b-th>
          <b-td>
            <b-dropdown
              block
              size="sm"
              :text="filterStatusText(item)"
              variant="outline-success"
              class="text-success"
              v-if="item.filter"
              menu-class="w-100"
            >
              <b-dropdown-item
                v-for="status in filterStatus[item.filter]"
                :active="activeFilteredStatus(item, status)"
                :key="'filter_status_' + item.key + '_' + status.value"
                @click="changeFilterStatus(item, status)"
                >{{ status.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-td>
          <b-td class="text-right">{{ item.count.toLocaleString() }}</b-td>
          <b-td class="text-center">
            <b-button variant="primary" size="sm" class="w-100" @click="exportData(item)">
              <i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import { mapGetters } from 'vuex';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import ApiService from '@/common/api.service.js';
// import { formatDateForView } from '@/utils/time.js';
import { exportRequest } from '@/utils/download.js';
import Swal from 'sweetalert2';
export default {
  name: 'marketing_reports',
  components: {
    datePicker
  },
  data() {
    return {
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      reportObject: {
        registerUser: {
          title: 'Register User',
          note: 'User registered successfully',
          data: [
            { key: 'total', title: 'Total Registered User', count: 0 },
            { key: 'code', title: 'Users Registered With Code', count: 0 }
          ]
        },
        activeUser: {
          title: 'Active User',
          note: 'Users have orders or deposit transactions',
          data: [
            { key: 'total', title: 'Total Active User', count: 0 },
            { key: 'new', title: 'Total New User', count: 0 },
            { key: 'old', title: 'Total Old User', count: 0 },
            { key: 'order', title: 'Users Bought Tickets', count: 0, filter: 'user', filterValue: 0 },
            { key: 'deposit', title: 'Users Deposited', count: 0, filter: 'user', filterValue: 0 }
          ]
        },
        revenue: {
          title: 'Revenue (Orders)',
          data: [
            { key: 'order', title: 'Total Orders', count: 0, filter: 'user', filterValue: 0 },
            { key: 'ticket', title: 'Total Tickets', count: 0, filter: 'user', filterValue: 0 },
            { key: 'revenue', title: 'Total Revenue', count: 0, filter: 'user', filterValue: 0 }
          ]
        },
        deposit: {
          title: 'Deposit Money',
          data: [
            { key: 'total', title: 'Total Success Deposit Money', count: 0, filter: 'payment', filterValue: 0 },
            { key: 'new', title: 'New User Deposit Money', count: 0, filter: 'payment', filterValue: 0 },
            { key: 'old', title: 'Old User Deposit Money', count: 0, filter: 'payment', filterValue: 0 }
          ]
        },
        withdraw: {
          title: 'Withdraw Money',
          data: [{ key: 'total', title: 'Total Withdraw Money', count: 0, filter: 'withdrawPayment' }]
        },
        reward: {
          title: 'Reward Money',
          data: [{ key: 'total', title: 'Total Reward Money', count: 0 }]
        }
      },
      headers: [
        { label: 'Category' },
        { label: 'Title' },
        { label: 'Filter', class: 'text-center' },
        { label: 'Count', class: 'text-right' },
        { label: 'Actions', class: 'text-center' }
      ],
      items: [],
      isLoading: false,
      filterStatus: {
        user: [
          { value: 0, text: 'All Users' },
          { value: 1, text: 'New Users' },
          { value: 2, text: 'Old Users' }
        ],
        depositPayment: [{ value: 0, text: 'All Payment Methods' }],
        withdrawPayment: [{ value: 0, text: 'All Payment Methods' }]
      },
      filterDateList: [
        { value: 0, text: 'Today' },
        { value: 7, text: 'Last 7 days' },
        { value: 15, text: 'Last 15 days' },
        { value: 30, text: 'Last 30 days' },
        { value: -1, text: 'Custom' }
      ],
      filterDate: 0,
      from: null,
      to: null
    };
  },
  created() {
    this.mapData();
    this.getDepositMethods();
    this.getFromTo(new Date(), this.filterDate);
    this.getReportData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Marketing Reports' }]);
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    user() {
      return this.$store.getters.currentUser.data;
    }
  },
  methods: {
    resetFilter() {
      let reportObj = this.reportObject;
      for (let key of Object.keys(reportObj)) {
        for (let [index, data] of Object.entries(reportObj[key].data)) {
          if (typeof data.filterStatus !== 'undefined') reportObj[key].data[index] = 0;
        }
      }
      this.reportObject = { ...reportObj };
      this.mapData();
    },
    changeFilterDate(date) {
      this.filterDate = date.value;
      this.getFromTo(new Date(), this.filterDate);
      this.resetFilter();
      this.getReportData();
    },
    filterDateText() {
      return this.filterDateList.find((date) => date.value == this.filterDate).text;
    },
    activeFilteredDate(date) {
      return this.filterDate == date.value;
    },
    mapData() {
      let items = [];
      Object.keys(this.reportObject).forEach((obj) => {
        this.reportObject[obj].data.forEach((data, i) => {
          if (i == 0)
            data.category = {
              count: this.reportObject[obj].data.length,
              title: this.reportObject[obj].title,
              note: this.reportObject[obj].note || ''
            };
          items.push({
            ...data,
            key: `${obj}.${data.key}`
          });
        });
      });
      this.items = [...items];
    },
    activeFilteredStatus(item, status) {
      return status.value == item.filterStatus;
    },
    filterStatusText(item) {
      const filterStatus = this.filterStatus[item.filter];
      const activeStatus = filterStatus.find((data) => data.value == item.filterStatus);
      return activeStatus ? activeStatus.text : filterStatus[0].text;
    },
    changeFilterStatus(item, status) {
      if (item.filterStatus == status.value) return;
      else item.filterStatus = status.value;
      const dataFilterIndex = this.items.findIndex((data) => data.key == item.key);
      this.items.splice(dataFilterIndex, 1, { ...item, filterStatus: status.value });
      const [cate, key] = item.key.split('.');
      const keyIndex = this.reportObject[cate].data.findIndex((data) => data.key == key);
      let data = this.reportObject[cate].data;
      data.splice(keyIndex, 1, { ...item, key, filterStatus: status.value });
      this.reportObject = { ...this.reportObject, [cate]: { ...this.reportObject[cate], data } };
      this.getReportData(item);
    },
    getDepositMethods() {
      ApiService.get('allpayment').then((resp) => {
        const depositPayments = resp.data.data.filter(
          (payment) => payment.allow_deposit || ['wescan', 'referral', 'revenue_cms'].includes(payment.handler)
        );
        const withdrawPayments = resp.data.data.filter((payment) => payment.allow_withdraw);
        this.filterStatus.payment = [{ value: 0, text: 'All Payment Methods' }, ...depositPayments];
        this.filterStatus.withdrawPayment = [{ value: 0, text: 'All Payment Methods' }, ...withdrawPayments];
      });
    },
    getReportData(item = null) {
      this.isLoading = true;
      ApiService.get(
        'marketing-reports',
        `?type=${item && item.key ? item.key : ''}&filter=${item && item.filter ? item.filter : ''}&status=${
          item && item.filterStatus ? item.filterStatus : 0
        }&from=${this.from ? this.getFrom() : ''}&to=${this.to ? this.getTo() : ''}`
      )
        .then((resp) => {
          let data = resp.data.data;
          const [cate, subCate] = item && item.key ? item.key.split('.') : ['', ''];
          for (let key of Object.keys(data)) {
            if (cate == key || cate == '') {
              let userData = this.reportObject[key].data.map((user) =>
                subCate == '' || subCate == user.key ? { ...user, count: data[key][user.key] || 0 } : user
              );
              this.reportObject[key].data = userData;
            }
          }
          this.reportObject = { ...this.reportObject };
          this.mapData();
        })
        .catch((err) => {
          Swal.fire({
            icon: 'danger',
            title: 'Error occured',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    exportData(item) {
      exportRequest(
        `exports/marketing-reports?type=${item.key || ''}&filter=${item.filter || ''}&status=${
          item.filterStatus || 0
        }&from=${this.from ? this.getFrom() : ''}&to=${this.to ? this.getTo() : ''}`
      );
    },
    getFrom() {
      let date = new Date(this.from);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      let from = new Date(date).toISOString();
      return from;
    },
    getTo() {
      let date = new Date(this.to);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      let to = new Date(date).toISOString();
      return to;
    },
    getFromTo(date, num) {
      if (num < 0) {
        if (!this.from && !this.to) num = 1;
        else return { from: this.from, to: this.to };
      }
      let from, to;
      to = new Date(date);
      date.setDate(date.getDate() - num);
      from = new Date(date);
      this.from = from;
      this.to = to;
      return { from, to };
    }
  }
};
</script>
